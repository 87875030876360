// src/store/modules/hotels_dates.js

import standardAPI from '@/api/standard_items'; // Keep this if you plan to switch back to real API later

const responseUrl = '/hotels/dates';

// Mock Data
const mockDates = [
  {
    id: 1,
    title_ru: 'Период Весна',
    title_en: 'Spring Period',
    alias: 'spring-period',
    start_date: '2024-03-01',
    end_date: '2024-05-31',
    is_active: true,
  },
  {
    id: 2,
    title_ru: 'Период Лето',
    title_en: 'Summer Period',
    alias: 'summer-period',
    start_date: '2024-06-01',
    end_date: '2024-08-31',
    is_active: true,
  },
  {
    id: 3,
    title_ru: 'Период Осень',
    title_en: 'Autumn Period',
    alias: 'autumn-period',
    start_date: '2024-09-01',
    end_date: '2024-11-30',
    is_active: false,
  },
  {
    id: 4,
    title_ru: 'Период Зима',
    title_en: 'Winter Period',
    alias: 'winter-period',
    start_date: '2024-12-01',
    end_date: '2024-02-28',
    is_active: true,
  },
];

const state = {
  dates: [],
  dates_MetaData: {
    meta: {
      current_page: 1,
      per_page: 10,
      total: mockDates.length,
      last_page: 1,
    },
    links: {
      previous: null,
      next: null,
    },
  },
  dates_status: '',
  dates_page: 1,
  dates_perPage: 10,
};

const getters = {
  get_dates: (state) => state.dates,
  get_dates_status: (state) => state.dates_status,
};

const actions = {
  // Set the current page number
  set_current_page({ commit }, page) {
    commit('SET_CURRENT_PAGE', page);
    // Fetch data again if needed when page changes
    commit('DATA_DATES_REQUEST');
    commit('DATA_DATES_UPDATE_SUCCESS', { data: mockDates.slice(0, state.dates_perPage), meta: state.dates_MetaData.meta, links: state.dates_MetaData.links });
    commit('DATA_DATES_READY');
  },

  // Set the number of items per page
  set_perPage({ commit }, max) {
    commit('SET_PER_PAGE', max);
    // Fetch data again if needed when perPage changes
    commit('DATA_DATES_REQUEST');
    commit('DATA_DATES_UPDATE_SUCCESS', { data: mockDates.slice(0, max), meta: { ...state.dates_MetaData.meta, per_page: max }, links: state.dates_MetaData.links });
    commit('DATA_DATES_READY');
  },

  // Fetch dates with options (e.g., pagination, filters)
  fetch_dates({ commit }, getOptions) {
    commit('DATA_DATES_REQUEST');
    // Simulate API delay
    return new Promise((resolve) => {
      setTimeout(() => {
        const { page, perPage } = getOptions.pageOptions;
        const start = (page - 1) * perPage;
        const end = start + perPage;
        const paginatedData = mockDates.slice(start, end);
        const meta = {
          current_page: page,
          per_page: perPage,
          total: mockDates.length,
          last_page: Math.ceil(mockDates.length / perPage),
        };
        const links = {
          previous: page > 1 ? `/hotels/dates?page=${page - 1}` : null,
          next: page < meta.last_page ? `/hotels/dates?page=${page + 1}` : null,
        };
        commit('DATA_DATES_UPDATE_SUCCESS', { data: paginatedData, meta, links });
        commit('DATA_DATES_READY');
        resolve({ data: paginatedData, meta, links });
      }, 500); // 500ms delay to simulate network
    });
  },

  // Add a new date period
  add_date({ commit }, itemData) {
    commit('DATA_DATES_REQUEST');
    return new Promise((resolve) => {
      setTimeout(() => {
        const newId = mockDates.length + 1;
        const newDate = { id: newId, ...itemData };
        mockDates.push(newDate);
        commit('DATA_DATES_UPDATE_SUCCESS', { data: mockDates, meta: state.dates_MetaData.meta, links: state.dates_MetaData.links });
        commit('DATA_DATES_READY');
        resolve({ status: 'created', data: newDate });
      }, 300);
    });
  },

  // Edit an existing date period
  edit_date({ commit }, itemData) {
    commit('DATA_DATES_REQUEST');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const index = mockDates.findIndex((date) => date.id === itemData.id);
        if (index !== -1) {
          mockDates[index] = { ...mockDates[index], ...itemData };
          commit('DATA_DATES_UPDATE_SUCCESS', { data: mockDates, meta: state.dates_MetaData.meta, links: state.dates_MetaData.links });
          commit('DATA_DATES_READY');
          resolve({ status: 'updated', data: mockDates[index] });
        } else {
          commit('DATA_DATES_READY');
          reject({ status: 'not_found', data: 'Date period not found' });
        }
      }, 300);
    });
  },

  // Remove one or multiple date periods
  remove_dates({ commit }, itemData) {
    commit('DATA_DATES_REQUEST');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const idsToRemove = itemData; // Expecting an array of IDs
        const initialLength = mockDates.length;
        mockDates = mockDates.filter((date) => !idsToRemove.includes(date.id));
        if (mockDates.length < initialLength) {
          commit('DATA_DATES_UPDATE_SUCCESS', { data: mockDates, meta: { ...state.dates_MetaData.meta, total: mockDates.length }, links: state.dates_MetaData.links });
          commit('DATA_DATES_READY');
          resolve({ status: 'deleted' });
        } else {
          commit('DATA_DATES_READY');
          reject({ status: 'error', data: 'No dates were deleted' });
        }
      }, 300);
    });
  },

  // Reorder a single date period
  reorder_date({ commit }, orderData) {
    commit('DATA_DATES_REQUEST');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const { id, newPosition } = orderData; // Expecting { id, newPosition }
        const index = mockDates.findIndex((date) => date.id === id);
        if (index !== -1 && newPosition >= 0 && newPosition < mockDates.length) {
          const [movedDate] = mockDates.splice(index, 1);
          mockDates.splice(newPosition, 0, movedDate);
          commit('DATA_DATES_UPDATE_SUCCESS', { data: mockDates, meta: state.dates_MetaData.meta, links: state.dates_MetaData.links });
          commit('DATA_DATES_READY');
          resolve({ status: 'reorder', data: mockDates });
        } else {
          commit('DATA_DATES_READY');
          reject({ status: 'error', data: 'Invalid reorder parameters' });
        }
      }, 300);
    });
  },

  // Set the order of date periods (bulk reorder)
  set_date_order({ commit }, itemData) {
    commit('DATA_DATES_REQUEST');
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Assuming itemData is the entire ordered array
        mockDates = [...itemData];
        commit('DATA_DATES_UPDATE_SUCCESS', { data: mockDates, meta: state.dates_MetaData.meta, links: state.dates_MetaData.links });
        commit('DATA_DATES_READY');
        resolve({ status: 'reorder', data: mockDates });
      }, 300);
    });
  },
};

const mutations = {
  SET_CURRENT_PAGE: (state, pageNumber) => {
    state.dates_page = pageNumber;
  },
  SET_PER_PAGE: (state, max) => {
    state.dates_perPage = max;
    state.dates_MetaData.meta.per_page = max;
  },
  DATA_DATES_READY: (state) => {
    state.dates_status = 'ready';
  },
  DATA_DATES_REQUEST: (state) => {
    state.dates_status = 'loading';
  },
  DATA_DATES_UPDATE_SUCCESS: (state, response) => {
    if (response && response.meta) {
      state.dates_MetaData.meta = response.meta;
      state.dates_MetaData.links = response.links;
    }
    if (response && response.data) {
      state.dates = response.data || [];
    }
    state.dates_status = 'success';
  },
  DATA_DATES_ERROR: (state) => {
    state.dates_status = 'error';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};