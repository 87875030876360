// src/router/modules/hotels/dates.js

import { roleAdmin, roleManager, roleSuperAdmin } from '@/mixins/roles';

const HotelsDates = () => import('@/pages/hotels/dates.vue');

export default [
  {
    path: '/hotels/dates',
    name: 'HotelsDates',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Dates Periods',
      ],
    },
    component: HotelsDates,
  },
  {
    path: '/hotels/dates/:id',
    name: 'HotelsDatesForm',
    meta: {
      auth: {
        roles: [roleAdmin, roleSuperAdmin, roleManager],
        forbiddenRedirect: '/forbidden',
      },
      title: [
        'SPA',
        'Date Period',
      ],
    },
    component: HotelsDates,
  },
];