import DashboardLayout from '../layouts/Dashboard.vue';

// GeneralViews
import Login from '../pages/login.vue';
import NotFound from '../pages/notFound.vue';

// Dashboard pages
import BlogArticles from './modules/blog/articles';
import BlogAuthors from './modules/blog/authors';
import BlogCategories from './modules/blog/categories';
import Agencies from './modules/agencies';
import Agents from './modules/agents';
import Attractions from './modules/attractions';
import Cities from './modules/cities';
import Clients from './modules/clients';
import CollectionPoints from './modules/collectionPoints';
import Contacts from './modules/contacts';
import Currencies from './modules/currencies';
import Events from './modules/events';
import ExcursionCategories from './modules/excursionCategories';
import ExcursionCategoryTypes from './modules/excursionCategoryTypes';
import ExcursionDirections from './modules/excursionDirections';
import ToursDirections from './modules/toursDirections';
import Holidays from './modules/holidays';
import ExcursionTypes from './modules/excursionTypes';
import Excursions from './modules/excursions';
import Feedback from './modules/feedback';
// import HolidayCities from './modules/holiday/cities';
// import HolidayContraindications from './modules/holiday/contraindications';
// import HolidayCountries from './modules/holiday/countries';
// import HolidayHotelAdditional from './modules/holiday/hotelAdditional';
// import HolidayHotelFood from './modules/holiday/hotelFood';
// import HolidayHotelItems from './modules/holiday/hotelItems';
// import HolidayHotelTypes from './modules/holiday/hotelTypes';
// import HolidayHotels from './modules/holiday/hotels';
// import HolidayReadings from './modules/holiday/readings';
// import HolidayRegions from './modules/holiday/regions';
// import HolidayResorts from './modules/holiday/resorts';
// import HolidayTreatments from './modules/holiday/treatments';
import Levels from './modules/levels';
import Logs from './modules/logs';
import Managers from './modules/managers';
import Notifications from './modules/notifications';
import Offices from './modules/offices';
import Orders from './modules/orders';
import Packages from './modules/packages';
import Pages from './modules/pages/pages';
import PagesSections from './modules/pages/sections';
import Partnership from './modules/partnership';
import PcCategories from './modules/pcCategories';
import PriceTypes from './modules/priceTypes';
import Redirects from './modules/redirects';
import Regions from './modules/regions';
import Seo from './modules/seo';
import Settings from './modules/settings';
import Subscribers from './modules/subscribers';
import Team from './modules/team/team';
import TeamCategories from './modules/team/categories';
import Transfers from './modules/transfers';
import TransitPoints from './modules/transitPoints';
import WorldToursAttractions from './modules/world-tours/attractions';
import WorldToursCategories from './modules/world-tours/categories';
import WorldToursCategoryTypes from './modules/world-tours/categoryTypes';
import WorldToursCountries from './modules/world-tours/countries';
import WorldToursCities from './modules/world-tours/cities';
import WorldToursExcursions from './modules/world-tours/excursions';
import WorldToursGuides from './modules/world-tours/guides';
import OrganisationToursGuides from './modules/organisation-tours/guides';
import WorldToursMovementTypes from './modules/world-tours/movementTypes';
import WorldToursOrders from './modules/world-tours/orders';
import OrganisationToursOrders from './modules/organisation-tours/orders';
import WorldToursPriceTypes from './modules/world-tours/priceTypes';
import WorldToursRequests from './modules/world-tours/requests';
import WorldToursSections from './modules/world-tours/sections';
import HotelsCountries from './modules/hotels/countries';
import HotelsCities from './modules/hotels/cities';
import HotelsStars from './modules/hotels/stars';
import HotelsFlights from './modules/hotels/flights';
import HotelsPackages from './modules/hotels/packages';
import HotelsTransfers from './modules/hotels/transfers';
import HotelsParameters from './modules/hotels/parameters';
import HotelsDates from './modules/hotels/dates';
import HotelsProcedures from './modules/hotels/procedures';
import HotelsRestTypes from './modules/hotels/restTypes';
import HotelsRoomParameters from './modules/hotels/roomParameters';
import HotelsFoodTypes from './modules/hotels/foodTypes';
import HotelsHotels from './modules/hotels/hotels';
import HotelsOrders from './modules/hotels/orders';
import OrganisationToursTourOperators from './modules/organisation-tours/tourOperators';
import OrganisationTours from './modules/organisation-tours/tours';
import OrganisationToursTourTypes from './modules/organisation-tours/tourTypes';
import OrganisationToursCountries from './modules/organisation-tours/countries';
import OrganisationToursAttractions from './modules/organisation-tours/attractions';
import OrganisationToursSections from './modules/organisation-tours/sections';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false },
  },
  {
    path: '/',
    component: DashboardLayout,
    meta: { auth: true },
    children: [
      ...BlogArticles,
      ...BlogAuthors,
      ...BlogCategories,
      ...Agencies,
      ...Agents,
      ...Attractions,
      ...Cities,
      ...Clients,
      ...CollectionPoints,
      ...Contacts,
      ...Currencies,
      ...Events,
      ...ExcursionCategories,
      ...ExcursionCategoryTypes,
      ...ExcursionDirections,
      ...ToursDirections,
      ...Holidays,
      ...ExcursionTypes,
      ...Excursions,
      ...Feedback,
      // ...HolidayCities,
      // ...HolidayContraindications,
      // ...HolidayCountries,
      // ...HolidayHotelAdditional,
      // ...HolidayHotelFood,
      // ...HolidayHotelItems,
      // ...HolidayHotelTypes,
      // ...HolidayHotels,
      // ...HolidayReadings,
      // ...HolidayRegions,
      // ...HolidayResorts,
      // ...HolidayTreatments,
      ...Levels,
      ...Logs,
      ...Managers,
      ...Notifications,
      ...Offices,
      ...Orders,
      ...Packages,
      ...Pages,
      ...PagesSections,
      ...Partnership,
      ...PcCategories,
      ...PriceTypes,
      ...Redirects,
      ...Regions,
      ...Seo,
      ...Settings,
      ...Subscribers,
      ...Team,
      ...TeamCategories,
      ...Transfers,
      ...TransitPoints,
      ...WorldToursAttractions,
      ...WorldToursCategories,
      ...WorldToursCategoryTypes,
      ...WorldToursCountries,
      ...WorldToursCities,
      ...WorldToursExcursions,
      ...WorldToursGuides,
      ...OrganisationToursGuides,
      ...WorldToursMovementTypes,
      ...WorldToursOrders,
      ...WorldToursPriceTypes,
      ...WorldToursRequests,
      ...WorldToursSections,
      ...HotelsCountries,
      ...HotelsCities,
      ...HotelsDates, 
      ...HotelsStars,
      ...HotelsFlights,
      ...HotelsPackages,
      ...HotelsTransfers,
      ...HotelsParameters,
      ...HotelsProcedures,
      ...HotelsRestTypes,
      ...HotelsRoomParameters,
      ...HotelsFoodTypes,
      ...HotelsHotels,
      ...HotelsOrders,
      ...OrganisationToursTourOperators,
      ...OrganisationTours,
      ...OrganisationToursTourTypes,
      ...OrganisationToursCountries,
      ...OrganisationToursAttractions,
      ...OrganisationToursSections,
      ...OrganisationToursOrders,
    ],
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];

export default routes;
